import React, { ReactElement, useRef, useState } from "react"
import { graphql, Link, useStaticQuery, WrapPageElementBrowserArgs, WrapPageElementNodeArgs } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { useLocation, WindowLocation } from "@reach/router"
import { Helmet } from "react-helmet"
import { Player, PlayerProps } from "./player"
import { Sticker } from "./sticker"
import { EpisodeListItem } from "./episodeListItem"
import { Ticker } from "./ticker"
import { Noise } from "./noise"
import { countDigits, filterEpisodes, formatEp, guessEp, isOrContainsActiveElement } from "../utilities"
import * as colors from "../../colors.sl"
import { useEventCondition, useEventConditions} from "../hooks/useEventCondition"

const query = graphql`
  query PodcastQuery {
    feedPodcastMeta {
      # author
      copyright
      # creator
      description
      feedUrl
      # generator
      id
      image {
        link
        title
        url
      }
      itunes {
        # author
        categories
        categoriesWithSubs {
          name
        }
        explicit
        image
        owner {
          email
          name
        }
        summary
      }
      language
      # lastBuildDate
      link
      paginationLinks {
        self
      }
      title
    }
    allFeedPodcast(sort: {fields: isoDate, order: DESC}) {
      edges {
        node {
          content {
            encoded
          }
          enclosure {
            length
            type
            url
          }
          guid
          id
          isoDate(formatString: "dddd, MMMM Do, YYYY")
          longDate: isoDate(formatString: "dddd, MMMM Do, YYYY")
          shortDate: isoDate(formatString: "MM/DD/YY")
          itunes {
            duration
            episode
            image {
              attrs {
                href
              }
            }
          }
          link
          pubDate
          title
        }
      }
    }
    podcastCover: file(relativePath: {eq: "how-long-gone-cover.jpg"}) {
      childImageSharp {
				at4096: resize(width: 4096) {
        	src
        }
      }
    }
    hostPortrait: file(relativePath: {eq: "how-long-gone-portrait.jpg"}) {
      childImageSharp {
        gatsbyImageData
      }
    }
    #tourPoster: file(relativePath: {eq: "how-long-gone-tour-poster.jpg"}) {
    #  childImageSharp {
    #    gatsbyImageData
    #  }
    #}
  }
`

const Layout = ({ children, JSDOM }: WrapPageElementBrowserArgs | WrapPageElementNodeArgs & { JSDOM?: any } ): ReactElement => {
  let initialIndex = parseInt(process.env.GATSBY_COLOR_INDEX ?? `0`, 10)
  if(typeof colors[initialIndex] === `undefined`) initialIndex = 0

  const data = useStaticQuery<GatsbyTypes.PodcastQueryQuery>(query),
    location = useLocation() as WindowLocation<any>,
    digits = countDigits(data?.allFeedPodcast?.edges?.length),
    infoButton = useRef<HTMLButtonElement>(null),
    infoHover = useEventConditions([{
      event: `mouseenter`,
      handler: () => true,
      element: infoButton.current ?? undefined,
    }, {
      event: `mouseleave`,
      handler: () => false,
      element: infoButton.current ?? undefined,
    }]),
    infoFocus = useEventCondition({
      event: `focusin`,
      handler: () => isOrContainsActiveElement(infoButton.current),
    }),
    infoVisible = infoHover || infoFocus,
    filteredPodcasts = data?.allFeedPodcast?.edges
      ? filterEpisodes(data.allFeedPodcast.edges)
      : [],
    podcasts = location?.state?.sort
      ? filteredPodcasts.slice().reverse()
      : filteredPodcasts.slice(),
    [colorIndex, setColorIndex] = useState<number>(initialIndex),
    [colorPrimary, setColorPrimary] = useState(colors[initialIndex][0]),
    [colorSecondary, setColorSecondary] = useState(colors[initialIndex][1]),
    hostPortrait = data.hostPortrait?.childImageSharp?.gatsbyImageData
    // tourPoster = data.tourPoster?.childImageSharp?.gatsbyImageData ? data.tourPoster.childImageSharp.gatsbyImageData as IGatsbyImageData : undefined,
    // posterButton = useRef<HTMLButtonElement>(null),
    // posterDismissed = useEventCondition({
    //   event: `click`,
    //   handler: () => true
    // }),
    // posterHover = useEventConditions([{
    //   event: `mouseenter`,
    //   handler: () => true,
    //   element: posterButton.current ?? undefined,
    // }, {
    //   event: `mouseleave`,
    //   handler: () => false,
    //   element: posterButton.current ?? undefined,
    // }]),
    // posterFocus = useEventCondition({
    //   event: `focusin`,
    //   handler: () => isOrContainsActiveElement(posterButton.current),
    // }),
    // posterVisible = !posterDismissed || posterHover || posterFocus

  const [episode, setEpisode] = useState<PlayerProps>()

  const tourDates = false /* (
    <>
        12/06/24
        <span>
          <a href="https://www.axs.com/events/691859/how-long-gone-tickets">Los Angeles, CA</a>, El Rey Theatre w/ Robby Hoffman
        </span>
    </>
  ) */

  const info = (
    <div className="w-80 overflow-y-auto p-5 text-left text-xs text-black bg-white/80 lg:bg-white/100 shadow-md transition-opacity duration-100" style={{ maxHeight: `inherit` }}>
      <div className="mb-4">
        <h2 className="font-bold text-2xs uppercase mb-1">About us</h2>
        <p>How Long Gone is a bi-coastal elite podcast from old friends and podcast professionals, Chris Black and Jason Stewart. CB and TJ deliver their takes on pop culture, fashion, music, and more.</p>
      </div>
      {hostPortrait && (
        <div className="mb-4">
          <GatsbyImage
            alt="A grainy photograph features How Long Gone hosts Chris Black and Jason Stewart reclining in the grass before a fountain containing a large golden statue of a leaping man."
            image={hostPortrait}
            className="w-full pointer-events-none"
          />
        </div>
      )}
      {/* <div className="lg:hidden mb-4">
        <h2 className="font-bold text-2xs uppercase mb-1">Tour Dates</h2>
        <p>
          01/26/23 London, 5 Carlos Place w Special Guest<br />
          01/29/23 <a href="https://dice.fm/event/wlgmx-how-long-gone-live-29th-jan-moth-club-london-tickets?lng=en-US">London</a>, Moth Club w Issy Wood
        </p>
      </div> */}
      {tourDates && (
        <div className="lg:hidden mb-4">
          <h2 className="font-bold text-2xs uppercase mb-1">Tour Dates</h2>
          <p className="grid grid-cols-[auto_auto] gap-x-[1ch]">{tourDates}</p>
        </div>
      )}
      <div className="mb-4">
        <h2 className="font-bold text-2xs uppercase mb-1">Links</h2>
        <p>
          Check out our show on <a href="https://open.spotify.com/show/2ov19zdbTbmgmKQmmd7Go7?si=qdZdZNSSR-etnyFl4beiXg">Spotify</a> and <a href="https://podcasts.apple.com/us/podcast/how-long-gone/id1503346367c">Podcasts</a><br />
          Follow @donetodeath on <a href="https://twitter.com/donetodeath">Twitter</a> &amp; <a href="https://www.instagram.com/donetodeathprojects/">Instagram</a><br />
          Follow @themjeans on <a href="https://twitter.com/themjeans">Twitter</a> &amp; <a href="https://www.instagram.com/themjeans/">Instagram</a>
          <br />Press: <a href="https://www.grubstreet.com/2020/11/jason-stewart-grub-street-diet.html">Grub Street</a>, <a href="https://www.interviewmagazine.com/culture/chris-black-and-matty-matheson-on-how-to-master-your-personal-brand">Interview</a>, <a href="https://www.nytimes.com/2021/09/22/style/how-long-gone-podcast.html">New York Times</a>, <a href="https://www.vogue.com/article/how-long-gone-podcast">Vogue</a>, <a href="https://wwd.com/eye/people/how-long-gone-live-new-york-bowery-ballroom-1235406374/">WWD</a>
        </p>
      </div>
      <div className="mb-4">
        <h2 className="font-bold text-2xs uppercase mb-1">Contact</h2>
        <p>
          General: <a href="mailto:gone@howlonggone.com">gone@howlonggone.com</a><br />
          Agent: <a href="mailto:josh.lindgren@caa.com">josh.lindgren@caa.com</a><br />
          Partnerships: <a href="mailto:susannaf@brigadetalent.com">susannaf@brigadetalent.com</a><br />
          Live Event Booking: <a href="https://www.highroadtouring.com/artists/how-long-gone/">High Road Touring</a><br />
          Label: <a href="https://jagjaguwar.com/">Jagjaguwar</a>
        </p>
      </div>
      <div className="">
        <h2 className="font-bold text-2xs uppercase mb-1">Website</h2>
        <p><a href="https://studio.sl">Spevack Loeb</a></p>
      </div>
    </div>
  )

  return (
    <>
      <Helmet
        defaultTitle="How Long Gone"
        titleTemplate="%s | How Long Gone"
      >
        <meta name="twitter:card" content="summary_large_image" />

        {/* TODO: Update with final URL */}
        <link rel="canonical" href="https://howlonggone.com" />
        <meta property="og:url" content="https://howlonggone.com" />

        <meta property="og:title" content="How Long Gone" />
        <meta name="twitter:title" content="How Long Gone" />

        <meta name="description" content="How Long Gone is a bi-coastal elite podcast from old friends and podcast professionals, Chris Black and Jason Stewart. CB and TJ deliver their takes on pop culture, fashion, music, and more." />
        <meta property="og:description" content="How Long Gone is a bi-coastal elite podcast from old friends and podcast professionals, Chris Black and Jason Stewart. CB and TJ deliver their takes on pop culture, fashion, music, and more." />
        <meta name="twitter:description" content="How Long Gone is a bi-coastal elite podcast from old friends and podcast professionals, Chris Black and Jason Stewart. CB and TJ deliver their takes on pop culture, fashion, music, and more." />

        {/* TODO: Update with final URL */}
        <meta property="og:image" content={`https://howlonggone.com${data?.podcastCover?.childImageSharp?.at4096?.src}`} />
        <meta name="twitter:image" content={`https://howlonggone.com${data?.podcastCover?.childImageSharp?.at4096?.src}`} />
      </Helmet>
      <div className="relative h-full w-full">
        <style>{`
          :root {
            --color-primary: ${colorPrimary};
            --color-secondary: ${colorSecondary};
          }
        `}
        </style>
        {/* Splash */}
        {/* {tourPoster && (
          <div className={`poster fixed z-40 inset-0 flex items-center justify-center cursor-pointer ${posterVisible ? `opacity-100` : `opacity-0`} pointer-events-none transition-opacity duration-100`}>
            <style>{`
              .poster [data-placeholder-image] {
                width: 100%;
                max-width: calc(100vh / var(--data-ratio));
                height: 0;
                padding-top: calc(var(--data-ratio) * 100%);
                top: 50% !important;
                left: 50% !important;
                transform: translate(-50%, -50%);
              }
            `}</style>
            <GatsbyImage
              alt="A tour poster shows dates for the January 2023 How Long Gone trip to London."
              className="h-full"
              image={tourPoster}
              loading="eager"
              objectFit="contain"
              style={{
                [`--data-ratio` as string]: `${tourPoster.height / tourPoster.width}`,
              }}
            />
          </div>
        )} */}
        {/* Mobile logo */}
        <h2 className="lg:hidden py-6 mx-2 sm:mx-4 font-windsor text-7xl sm:text-8xl text-center text-primary"><Link to="/" state={{ autoplay: true }} className="no-underline">How Long<br />Gone</Link></h2>
        {/* Desktop logo */}
        <h2 className="hidden lg:block fixed top-0 left-0 mt-12 w-1/2 font-windsor text-7xl text-center text-primary"><Link to="/" state={{ autoplay: true }} className="no-underline">How Long<br />Gone</Link></h2>
        {/* Nav */}
        <nav className="pointer-events-none fixed z-30 w-full hidden lg:flex items-center justify-between py-6 text-xl">
          <button
            className="pointer-events-auto z-10 relative px-6 group"
            ref={infoButton}>
            <span className="group-hover:text-primary transition-colors duration-100">Info</span>
            <div className={`z-0 absolute left-0 top-0 pl-6 pt-10 ${infoVisible ? `opacity-100 pointer-events-auto` : `opacity-0 pointer-events-none`} transition-opacity duration-100`} style={{ maxHeight: `75vh` }}>
              {info}
            </div>
          </button>
          <Link
            className="pointer-events-auto px-6 text-left hover:text-primary transition-colors duration-100 no-underline"
            to={location.pathname}
            state={{
              scroll: true,
              sort: !location?.state?.sort ?? true
            }}
          >
            {formatEp(guessEp(podcasts[0].node), digits)} – {formatEp(guessEp(podcasts[podcasts.length - 1].node), digits)}
          </Link>
        </nav>
        {/* Current episode */}
        <div className="lg:fixed lg:w-1/2 lg:top-1/2 lg:-translate-y-1/2 pt-8 lg:pt-0 mx-2 lg:mx-0 flex flex-1 flex-col items-center justify-center">
          <div className="max-w-full w-86">
            {(
              <>
                {children && React.cloneElement(children as ReactElement, { setEpisode, JSDOM })}
                <Player {...episode} setEpisode={setEpisode} />
              </>
            )}
          </div>
        </div>
        {/* Mobile info */}
        <div className="flex lg:hidden justify-center mt-8">
          {info}
        </div>
        {/* Stickers */}
        {[1, 2].map((n, i) => (
          <Sticker key={i} href="https://shop.howlonggone.com/" target="_blank" rel="noopener">Shop</Sticker>
        ))}
        {/* All episodes */}
        <div className="mx-2 lg:mx-0 lg:relative lg:left-1/2 lg:top-1/8 lg:w-1/2 pt-8 lg:pt-0 pb-48 flex flex-1 flex-col items-center justify-start">
          <div className="max-w-full w-max lg:w-86">
            {/* Mobile header, sorting */}
            <div className="lg:hidden flex mb-2 border-b-2 border-black text-xl">
              <h2 className="font-bold">All episodes</h2>
              <Link
                className="ml-2 hover:text-primary transition-colors duration-100 no-underline"
                to={location.pathname}
                state={{
                  scroll: false,
                  sort: !location?.state?.sort ?? true
                }}
              >
                {formatEp(guessEp(podcasts[0].node), digits)} – {formatEp(guessEp(podcasts[podcasts.length - 1].node), digits)}
              </Link>
            </div>
            {/* Desktop tour announcement */}
            {tourDates && (
              <div className="hidden lg:block mb-10">
                <h2 className="block text-xl mb-2">
                  {/* <button
                    className="relative group no-underline py-0.5"
                    ref={posterButton}
                  > */}
                  <span className="py-0.5">
                    <span className="font-bold">Tour Dates</span>
                  </span>
                  {/* </button> */}
                </h2>
                <div className="text-xs children-font-normal">
                  <p className="grid grid-cols-[auto_auto] gap-x-[1ch]">{tourDates}</p>
                </div>
              </div>
            )}
            {/* Iterate over episodes */}
            {podcasts.map(edge =>
              <EpisodeListItem key={edge.node.guid} edge={edge} digits={digits} setEpisode={setEpisode} />
            )}
          </div>
        </div>
        {/* Border */}
        <div className="hidden lg:block fixed pointer-events-none left-0 top-0 h-screen w-1/2 border-r" style={{ borderColor: `#17171718` }} />
        {/* Ellipse */}
        <div className="fixed left-1/2 -translate-x-1/2 translate-y-full bg-primary" style={{
          height: `35.71vw`,
          width: `175vw`,
          bottom: `150px`,
          borderRadius: `100%`,
        }} />
        {/* Bottom padding to ensure small screens are covered */}
        <div className="fixed left-1/2 -translate-x-1/2 translate-y-full bg-primary" style={{
          height: `35.71vw`,
          width: `100vw`,
          bottom: `calc(150px - (35.71vw / 2))`,
        }} />
        {/* Ticker */}
        <div className="fixed z-10 w-screen lg:w-2/3 lg:left-1/2 -translate-y-1/2 lg:-translate-x-1/2" style={{
          bottom: `calc(150px / 4)`,
        }}>
          <Ticker gradientColor={colorPrimary} speed={40} />
        </div>
        <Noise />
      </div>
    </>
  )
}

export default Layout
export { Layout }